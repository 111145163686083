<template>

  <Navbar />
  
    <router-view class="outline-none"/>
  <Footer />
  </template>
  
  <script>
    // import { ref } from "@vue/reactivity";
    import Navbar from './components/navbar';
    import Footer from './components/Footer';
  export default {
  
    components:{
    Navbar,
    Footer
    }
  
  
  
    
  }
  </script>
  <style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  
  #nav {
    padding: 30px;
  }
  
  #nav a{
    font-weight: bold;
    color: #2c3e50;
  }
  
  .router-link-active {
    color:#f57d00;
   
  ;
  }
  
  
  </style>
  