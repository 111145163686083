<template>
    <div class="p-10">
        <div class="flex flex-wrap">
          <div class="w-full px-4">
            <div class="text-center ">
              <span class="font-semibold text-lg text-primary block">
               Empresas del 
              </span>
              <h2
                class="font-bold text-3xl sm:text-4xl text-otherblue md:text-[40px] text-dark mb-4"
              >
                Grupo UROS
              </h2>
             
            </div>
          </div>
        </div>
      <Carousel :autoplay="4000" :wrap-around="true">
        <Slide v-for="(image, i) in images" :key="i">
          <div class=""><img :src="image.img" class="w-48"  /></div>
        </Slide>
  
        <template #addons>
          <Pagination />
        </template>
      </Carousel>
    </div>
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import { Carousel, Pagination, Slide } from "vue3-carousel";
  import { ref } from "@vue/reactivity";
  
  import "vue3-carousel/dist/carousel.css";
  
  export default defineComponent({
    name: "Autoplay",
    components: {
      Carousel,
      Slide,
      Pagination,
    },
    setup() {
      let images = ref([
        {
          img: require("@/assets/empresas/28.png"),
        },
        {
          img: require("@/assets/empresas/nefrouros.png"),
        },
        {
          img: require("@/assets/empresas/Clinica-Putumayo.png"),
        },
        {
          img: require("@/assets/empresas/Clinica-Uros.png"),
        },
        {
          img: require("@/assets/empresas/logocsf.png"),
        },
         {
          img: require("@/assets/empresas/Centro-de-Urologia.png"),
        },
      ]);
  
      return { images };
    },
  });
  </script>
  