import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  // {
  // path: "/about",
  // name: "About",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  // component: () =>
  //   import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },

  {
    path: "/Servicios",
    name: "Servicios",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Servicios.vue"),
  },
  {
    path: "/Deberes-y-Derechos",
    name: "Deberes-y-Derechos",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Deberesyderechos.vue"),
  },
  {
    path: "/Acerca-De-Nosotros",
    name: "Acerca-De-Nosotros",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AcercadeNosotros.vue"),
  },
  {
    path: "/Copasst",
    name: "Copasst",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Copasst.vue"),
  },
  {
    path: "/En-La-Region",
    name: "En-La-Region",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EnlaRegion.vue"),
  },
  {
    path: "/Asociacion-De-Usuarios",
    name: "Asociacion-De-Usuarios",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AsociacionUsuarios.vue"),
  },

  /*FORMULARIOS */
  {
    path: "/Contactenos",
    name: "Contactenos",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contacto.vue"),
  },
  {
    path: "/PQR",
    name: "Pqr",
    component: () => import(/* webpackChunkName: "about" */ "../views/Pqr.vue"),
  },
  {
    path: "/Citas-En-Linea",
    name: "Citas-En-Linea",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CitasEnLinea.vue"),
  },
  {
    path: "/TrabajaConNosotros",
    name: "TrabajaConNosotros",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Trabajaconnosotros.vue"),
  },
  // RUTAS PLANES
  {
    path: "/PlanPlus",
    name: "PlanPlus",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Planes/PlanPlus.vue"),
  },
  {
    path: "/PlanElite",
    name: "PlanElite",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Planes/PlanElite.vue"),
  },
  {
    path: "/PlanGold",
    name: "PlanGold",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Planes/PlanGold.vue"),
  },
  // {
  //   path:"/Planes/:plan",
  //   name:"Planes",
  //   component: () =>
  //   import(/* webpackChunkName: "about" */ "../views/DetallesPlanes.vue"),
  // },
  {
    path: "/Sedes",
    name: "Sedes",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Sedes.vue"),
  },
  {
    path: "/Estados-Financieros",
    name: "EstadosFinancieros",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EstadosFinancieros.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NotFound.vue"),
  },
  {
    path: "/Estados-Financieros-2019",
    name: "EstadosFinancieros-2019",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/EstadosFinancieros/EF2019.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
